import { useHomePartialData } from "hooks/useHomePartialData"
import { useState, useEffect } from "react"
import useSize from "@react-hook/size"
import { Wrapper } from "components/Wrapper"
import { Button } from "components/Button"
import { useTranslator } from "components/Translator"
import { Text } from "components/Text"
import { TimesCircle } from "components/Icon"
import { useLocalStorage } from "@uidotdev/usehooks"
import { differenceInHours } from "date-fns"
import { useConnectedUser } from "./ConnectedUserProvider"

export const PromoBanner = () => {
  const { status, error, value } = useHomePartialData("promoBanner")

  useEffect(() => {
    if (status === "error") {
      console.error("Error while loading promo banner", error)
    }
  }, [status, error])

  if (status !== "success") {
    return null
  }

  if (!value) {
    return null
  }

  return <PromoBannerInner promoBanner={value} />
}

const PromoBannerInner = ({ promoBanner }) => {
  const { message, url, cta, hiddenDuration } = promoBanner
  const connectedUser = useConnectedUser()

  const [closeDate, setCloseDate] = useLocalStorage(
    `PROMO_BANNER_CLOSE_DATE_${promoBanner.id}_${
      connectedUser?.id ?? "anonymous"
    }`,
  )

  const handleClose = () => {
    const now = new Date()
    setCloseDate(now.toISOString())
  }

  const now = new Date()
  const shouldShow =
    !closeDate || differenceInHours(now, new Date(closeDate)) > hiddenDuration

  const translator = useTranslator()

  const [headerRef, setHeaderRef] = useState(document.getElementById("header"))
  const [, height] = useSize(headerRef)

  useEffect(() => {
    const header = document.getElementById("header")
    setHeaderRef(header)
  }, [])

  if (!shouldShow) {
    return null
  }

  return (
    <div
      className={
        "bg-primary-dark py-2 lg:py-3 pr-6 lg:pr-0 sticky z-20 text-white"
      }
      style={{ top: height }}
    >
      <Wrapper>
        <div
          className={
            "flex flex-col gap-3 lg:flex-row lg:gap-10 items-center justify-center"
          }
        >
          <Text variant={"headline5"} className={"text-center"}>
            {message}
          </Text>
          {url ? (
            <div className={"self-end lg:self-auto"}>
              <Button size={"small"} href={url} target={"_blank"}>
                <span className={"absolute inset-0"} />
                {cta ??
                  translator.trans("home.index.promoBanner.cta", null, "pages")}
              </Button>
            </div>
          ) : null}
        </div>
      </Wrapper>
      <button
        type={"button"}
        onClick={handleClose}
        className={
          "absolute top-2 right-2 before:content-[''] before:absolute before:-inset-3 lg:before:hidden"
        }
      >
        <TimesCircle className={"w-6 aspect-square lg:w-4"} />
      </button>
    </div>
  )
}
