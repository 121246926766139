import { useAsync } from "@react-hook/async"
import { Button } from "components/Button"
import { useRouter } from "components/Router"
import { useTranslator } from "components/Translator"
import { useState } from "react"
import { client } from "api/client"
import { Alerter } from "components/Alert"

export const ForumPostFollowButton = (props) => {
  const { following, request, toggle } = useForumPostFollowing(props)
  const translator = useTranslator()

  return (
    <Button
      type={"button"}
      onClick={toggle}
      busy={request.status === "loading"}
      variant={following ? "default" : "outline"}
      color={"secondary"}
      size={props.size}
    >
      {translator.trans(
        following
          ? "ForumPostFollowButton.unfollow"
          : "ForumPostFollowButton.follow",
        null,
        "components",
      )}
    </Button>
  )
}

const useForumPostFollowing = (props) => {
  const router = useRouter()
  const translator = useTranslator()

  const [following, setFollowing] = useState(props.initialFollowing)

  const [request, toggle] = useAsync(async () => {
    try {
      const response = await client.get(
        router.generate(
          following ? "app_user_forum_unfollow" : "app_user_forum_follow",
          { forumPostParentId: props.postId },
        ),
      )

      setFollowing((prevFollowing) => !prevFollowing)

      if (response.data.message) {
        Alerter.success(response.data.message)
      }

      if (props.onSuccess) {
        props.onSuccess()
      }
    } catch (err) {
      console.error(err)

      Alerter.error(
        err.response?.data?.message ||
          translator.trans("ForumPostFollowButton.error", null, "components"),
      )
    }
  })

  return { following, request, toggle }
}
