import classNames from "classnames"
import { useConnectedUser } from "components/ConnectedUserProvider"

export function useScrollMargins() {
  const connectedUser = useConnectedUser()

  return classNames({
    "scroll-mt-[177px]": connectedUser,
    "scroll-mt-[100px]": !connectedUser,
  })
}
