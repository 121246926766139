import cx from "classnames"
import PropTypes from "prop-types"
import { useTabs } from "components/Tab/Tabs"
import { useScrollMargins } from "hooks/useScrollMargins"

export function TabPanel(props) {
  const { children, id, className, ...rest } = props
  const context = useTabs()

  const scrollMargins = useScrollMargins()

  const classNames = cx(className, "relative", scrollMargins, {
    hidden: id !== context.currentPanel,
  })

  return (
    <div
      className={classNames}
      role="tabpanel"
      id={id}
      aria-labelledby={context.generateTabId(id)}
      {...rest}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  children: PropTypes.node,
}
