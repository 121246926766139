import cx from "classnames"
import PropTypes from "prop-types"
import { Text } from "components/Text"
import { useTabs } from "components/Tab/Tabs"
import { RawLink } from "components/Link"

export function Tab({ label, id, href, scrollToPanel }) {
  const { style, currentPanel, onChange, generateTabId } = useTabs()
  const Component = componentsByStyle.get(style)
  const Wrapper = href ? RawLink : "button"

  const isCurrent = currentPanel === id

  const onClick = () => {
    onChange(id)

    if (scrollToPanel) {
      const element = document.getElementById(id)
      setTimeout(() => element?.scrollIntoView({ behavior: "smooth" }), 0)
    }
  }

  return (
    <Wrapper
      type={!href ? "button" : undefined}
      id={generateTabId(id)}
      onClick={!href ? onClick : undefined}
      role="tab"
      aria-selected={isCurrent ? "true" : "false"}
      aria-controls={id}
      className="group focus:outline-none"
      href={href}
    >
      <Component isCurrent={isCurrent} label={label} />
    </Wrapper>
  )
}

Tab.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isCurrent: PropTypes.bool,
  id: PropTypes.string,
  ariaControls: PropTypes.string,
  onClick: PropTypes.func,
}

const DefaultTab = ({ label, isCurrent }) => {
  const classNames = cx(
    {
      "border border-grey-medium text-dark group-focus:border-primary-darker":
        isCurrent,
      "text-light group-focus:bg-primary-lighter group-hover:bg-primary-lighter":
        !isCurrent,
    },
    "py-1 px-2 lg:py-2 lg:px-3 rounded-3xl",
  )

  return (
    <Text variant="subtitle1" className={classNames}>
      {label}
    </Text>
  )
}

const LinkTab = ({ label, isCurrent }) => {
  return (
    <Text
      variant={isCurrent ? "headline6" : "tag"}
      className={cx({
        "underline text-primary-default": !isCurrent,
        "text-default": isCurrent,
      })}
    >
      {label}
    </Text>
  )
}

const componentsByStyle = new Map([
  ["default", DefaultTab],
  ["link", LinkTab],
])
