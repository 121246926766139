import { useRouter } from "components/Router"
import * as SuggestType from "enums/SuggestType"
import { client } from "api/client"
import { useAsyncEffect } from "@react-hook/async"

export function useAdvertSuggestions({ navigationType, from }) {
  const router = useRouter()

  const fetchAdvertsSuggestions = () => {
    const url = router.generate("app_home_suggest", {
      type: SuggestType.ADVERT,
      navigationType,
      from,
    })

    return client.get(url)
  }

  const fetchUsersSuggestions = () => {
    const url = router.generate("app_home_suggest", {
      type: SuggestType.USER,
      navigationType,
      from,
    })

    return client.get(url)
  }

  const suggestionsRequest = useAsyncEffect(async () => {
    try {
      const [adverts, users] = await Promise.all([
        fetchAdvertsSuggestions(),
        fetchUsersSuggestions(),
      ])

      return {
        adverts: {
          items: adverts.data.data.adverts,
          moreUrl: addFromParam(adverts.data.data.moreUrl),
        },
        users: {
          items: users.data.data.users,
          moreUrl: addFromParam(users.data.data.moreUrl),
        },
      }
    } catch (err) {
      console.error("Error while fetching suggestions", err)
      throw err
    }
  }, [])

  return suggestionsRequest
}

const addFromParam = (url) => {
  const urlWithFrom = new URL(url, window.location.href)
  urlWithFrom.searchParams.set("from", window.location.toString())

  return urlWithFrom.toString()
}
