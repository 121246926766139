import { Card, CardContent } from "components/Card"
import { Text } from "components/Text"
import { UserAvatar } from "domains/user/UserAvatar"
import { useTranslator } from "components/Translator"
import { RawLink } from "components/Link"
import { useRouter } from "components/Router"
import { AvatarSkeleton } from "components/AvatarSkeleton"

export const TestimonyCard = ({ testimony }) => {
  const translator = useTranslator()
  const router = useRouter()

  return (
    <Card spacing="small">
      <CardContent>
        <Text variant="body2" tag="p">
          {testimony.content}
        </Text>
      </CardContent>
      <CardContent>
        <div className="flex space-x-4 items-center">
          <div className="shrink-0 grow-0">
            <RawLink
              href={router.generate("app_account_profile_show", {
                userId: testimony.user.id,
              })}
              external
            >
              <UserAvatar alt="" user={testimony.user} size="small" />
            </RawLink>
          </div>

          <div>
            <Text variant="headline5" className="text-primary-dark">
              {testimony.user.fullName}
            </Text>
            <Text variant="caption" className="text-light">
              {new Date(testimony.updatedAt).toLocaleDateString(
                translator.locale,
              )}
            </Text>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export const TestimonyCardSkeleton = () => {
  return (
    <Card spacing="small">
      <CardContent>
        <div className={"grid gap-1"}>
          <div className={"rounded bg-grey-light h-4"}></div>
          <div className={"rounded bg-grey-light h-4"}></div>
          <div className={"rounded bg-grey-light h-4"}></div>
          <div className={"rounded bg-grey-light h-4"}></div>
        </div>
      </CardContent>
      <CardContent>
        <div className="flex space-x-4 items-center">
          <div className="shrink-0 grow-0">
            <AvatarSkeleton size={"small"} />
          </div>

          <div>
            <div className={"rounded bg-grey-light h-4"}></div>
            <div className={"rounded bg-grey-light h-4"}></div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
